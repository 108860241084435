// $sidebar-bg-color: #1d1d1d;
$sidebar-bg-color: #474745;
$submenu-bg-color: rgba(255, 255, 255, 0.05);
$submenu-bg-color-collapsed: rgba(29, 29, 29, 0.95);
$sidebar-color: #dadada;
$highlight-color: #d8d8d8;
$icon-bg-color: rgba(255, 255, 255, 0.05);
$icon-size: 35px;


@import '~react-pro-sidebar/dist/scss/styles.scss';

body {
    margin: 0;
    height: 100vh;
    color: #353535;
    background-image: url("./assets/noise_lines.png");
    font-family: sans-serif;
  }

#root {
    height: 100%;
}
  

.app {
    height: 100%;
    display: flex;
    position: relative;
    
    .btn-toggle {
      cursor: pointer;
      display: none;
    }
  
  
    .sidebar-btn-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      .sidebar-btn {
        padding: 1px 15px;
        border-radius: 40px;
        background: rgba(255, 255, 255, 0.05);
        color: #adadad;
        text-decoration: none;
        margin: 0 auto;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-overflow: ellipsis;
        overflow: hidden;
        span {
          margin-left: 5px;
          font-size: 13px;
        }
        &:hover {
          color: $highlight-color;
        }
      }
    }
  
    .collapsed {
      .sidebar-btn {
        display: inline-block;
        border-radius: 50%;
        width: 35px;
        height: 35px;
        line-height: 40px;
        padding: 0;
      }
    }
  
    main {
      padding: 24px;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      
      // header {
      //   margin-bottom: 16px;
      //   border-bottom: 1px solid #efefef;
      //   color: #000;
      //   background-color: #d8c3a5;
      // }
  
      footer {
        margin-top: auto;
        color: #888;
        text-align: center;
        a {
          text-decoration: none;
          color: #888;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  
    .block {
      display: flex;
      margin-bottom: 24px;
      font-size: 14px;
      color: #545454;
      > span {
        margin-left: 10px;
      }
    }
  
    @media (max-width: $breakpoint-md) {
      .btn-toggle {
        display: flex;
      }
    }
}




.badge {
    padding: 3px 6px;
    font-size: 9px;
    letter-spacing: 1px;
    border-radius: 15px;
    &.red {
      color: #ffffff;
      background: #d63030;
    }
    &.gray {
      color: #ffffff;
      background: #777777;
    }
    &.yellow {
      color: #000000;
      background: #ffd331;
    }
}  






// // added by Sumit


.note {
  background: #ece2e8;
  border-radius: 7px;
  box-shadow: 0 2px 5px #874CCC;
  padding: 10px;
  width: 840px;
  margin: 16px;
  float: left;
  margin-top: 90px;
}

img {
  max-width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
}


// #home-header{
//   width:100%;
//   height:50%;
//   background: url('./assets/headingBg1.jpg');
//   background-size: 100vw 40vh;
//   background-repeat: no-repeat;
  
// }

// .card {
//   max-width: 30px;
//   min-height: 20px;
//   display: flex;
//   flex-direction: column;
//   justify-content: center;

//   max-width: 800px;
//   height: 100px;
//   padding: 35px;
//   margin: auto;

//   border: 1px solid rgba(255, 255, 255, .25);
//   border-radius: 20px;
//   background-color: rgba(255, 255, 255, 0.45);
//   box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);

//   backdrop-filter: blur(7px);
// }